import React from 'react'
import { StaticQuery } from 'gatsby'
import _ from 'lodash'

import Layout from '../components/layout'
import PageFooter from '../components/PageFooter'
import BlogPostRow from '../components/BlogPostRow'
import mapMarkdownRemarks from '../components/mapMarkdownRemarks'

const BlogPost = ({ title, excerpt, link, image, date, align }) => {
  return (
    <BlogPostRow
      align={align}
      excerpt={excerpt}
      date={date}
      title={title}
      image={image}
      link={link}
    />
  )
}

const BlogPosts = ({ blogPosts, language }) => {
  return (
    <Layout language={language} pageTitle="Blog">
      <div className="section">
        <div className="container">
          <div className="row">
            <main id="main" className="col-md-12">
              {blogPosts.map((blogPost, index) => (
                <BlogPost
                  align={index % 2 ? 'right' : 'left'}
                  title={blogPost.title}
                  date={blogPost.date}
                  link={`/blog/${blogPost.slug}`}
                  image={_.get(blogPost, 'images[0]')}
                  excerpt={blogPost.excerpt}
                />
              ))}
            </main>
          </div>
        </div>
      </div>
      <PageFooter language={language} />
    </Layout>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        blogPosts: allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "BlogPost" } } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                language
                title
                images
                date
                type
              }
              html
              excerpt
            }
          }
        }
      }
    `}
    render={data => {
      const blogPosts = mapMarkdownRemarks(data.blogPosts.edges || [])
      return (
        <BlogPosts
          language={props.pageContext.language}
          blogPosts={_.filter(blogPosts, [
            'language',
            props.pageContext.language,
          ])}
          {...props}
        />
      )
    }}
  />
)
